import { DOMAIN } from '@shared/constants'
import Head from 'next/head'
import Script from 'next/script'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

function StoriesLightBox({ data }) {
  const stories = useRef()
  function initializeStoryCarousel(p) {
    stories.current = p.getStories()
  }

  useEffect(() => {
    const player = document.getElementById('story-player')
    const lightBox = document.getElementById('stories-lightbox')
    const storiesLinks = document.getElementById('enable-stories-player')
    const currentLocation = location.href

    player.addEventListener('ready', () => {
      initializeStoryCarousel(player)
    })

    function closePlayer(changeURL = true) {
      // console.log({ changeURL, currentLocation })
      player.pause()
      lightBox.setAttribute('hidden', 'true')
      changeURL && history.pushState({ isWebStories: false }, '', currentLocation)
    }

    function showPlayer(url, changeURL = true) {
      // console.log(player)
      player?.show(url)
      player.play()
      lightBox.removeAttribute('hidden')
      changeURL && history.pushState({ isWebStories: true }, '', url)
    }

    player.addEventListener('amp-story-player-close', () => closePlayer())

    player.addEventListener('navigation', (t) => {
      const i = stories.current[t.detail.index]
      i && i.href !== document.location.href && history.pushState({ isWebStories: true }, '', i.href)
    })

    storiesLinks?.addEventListener('click', (e) => {
      // console.log(e, typeof player.show === 'function')
      const enablePlayer = e.target.getAttribute('data-enable-stories-player')
      const isEnable = e.target.nodeName === 'A' && enablePlayer === 'true'
      if (isEnable && typeof player.show === 'function') {
        e.preventDefault()
        showPlayer(e?.target?.href)
      }
    })

    window.addEventListener('popstate', ({ state }) => {
      // console.log(state, location.href)
      if (state?.isWebStories && typeof player.show === 'function') {
        showPlayer(location.href, false)
      } else if (typeof player.pause === 'function') {
        closePlayer(false)
      }
      // const t = this.lightboxElement.classList.contains('show')
      // const i = this.stories.find((t) => t.href === document.location.href)
      // i ? (t || (this.lightboxElement.classList.toggle('show'), document.body.classList.toggle('web-stories-lightbox-open'), this.player.play()), this.player.show(i.href)) : t && s()
    })
  }, [data])

  if (data?.length) {
    return (
      <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark" id="stories-lightbox" style={{ zIndex: 9999 }} hidden>
        <Script src="https://cdn.ampproject.org/amp-story-player-v0.js" strategy="lazyOnload" defer />
        <Head>
          <link href="https://cdn.ampproject.org/amp-story-player-v0.css" rel="stylesheet" type="text/css" />
        </Head>
        <amp-story-player className="w-100 h-100" id="story-player">
          <script
            type="application/json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                controls: [
                  {
                    name: 'close',
                    position: 'start'
                  },
                  {
                    name: 'skip-to-next'
                  }
                ]
              })
            }}
          ></script>
          {data?.map((data, index) => (
            <a key={data?._id} href={DOMAIN + data?.oSeo?.sSlug + '/'}>
              {data?.sName}
            </a>
          ))}
        </amp-story-player>
      </div>
    )
  } else return null
}
StoriesLightBox.propTypes = {
  data: PropTypes.array
}
export default StoriesLightBox
